





































































































































































































































































.content {
	font-family: PingFangSC-Regular;
	// padding-bottom: 1.6rem;
	// font-family: PingFangSC-Medium;
}
.head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	// top: 0.88rem;
	padding-top: calc(env(safe-area-inset-top) + 0.45rem);
	left: 0;
	width: 100%;
	&::before{
		content: "";
		height: calc(env(safe-area-inset-top) + 3.16rem);
		width: 100%;
		background: url(../../assets/imgs/new_color/my_head_bg.png) no-repeat center top;
		background-size: 100% 100%;
		display: inline-block;
		position: absolute;
		top: 0;
	}
	.head_left {
		z-index: 100;
		margin-left: 0.6rem;
		display: flex;
		align-items: center;
		.head_center {
			margin-left: 0.36rem;
			.phone {
				color: #FFFFFF;
				font-size: 0.28rem;
				font-weight: 500;
				text-align: left;
			}
			.vip {
				height: 0.37rem;
				font-size: 0.32rem;
				line-height: 0.37rem;
				color: #EFBA0D;
				font-family: 'haose';
				font-weight: 550;
				img {
					margin-right: 0.05rem;
					width: 0.44rem;
					height: 0.2rem;
				}
			}
		}
		.img {
			width: 1rem;
			height: 1rem;
			img {
				background-color: #FFFFFF;
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
	}
	.head_right {
		margin-right: 0.6rem;
		.icon {
			img {
				width: 0.19rem;
				height: 0.32rem;
			}
		}
	}
}
.K_VIP {
	position: relative;
	z-index: 0.2rem;
	margin: 0 0.2rem;
	margin-top: 0.85rem;
	padding: 0.05rem 0;
	background-image: url(../../assets/imgs/new_color/Open_bg.png);
	background-size: 100% 100%;
	height: 0.86rem;
	.main7 {
		display: flex;
		justify-content: space-between;
		.section5 {
			margin-top: 0.14rem;
			margin-left: 1.2rem;
			.word7 {
				color: #2F4B9D;
				font-size: 0.24rem;
				font-weight: 550;
			}
		}
		.section6 {
			margin-top: 0.14rem;
			margin-right: 0.9rem;
			.word8 {
				font-size: 0.24rem;
				color: #FFFFFF;
				font-weight: 550;
			}
		}
	}
}
.seation {
	margin: 0 0.2rem;
	margin-top: 0.2rem;
	border-radius: 0.16rem;
	height: 1.11rem;
	line-height: 1.11rem;
	background-color: #FFFFFF;
	.qb {
		margin: 0 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.qb_left {
			display: flex;
			align-items: center;
			img {
				width: 0.6rem;
				height: 0.6rem;
			}
			.word7 {
				font-family: PingFangSC-Regular;
				margin-left: 0.12rem;
				color: #6F7989;
				font-size: 0.28rem;
			}
		}
		.qb_right {
			display: flex;
			align-items: center;
			img {
				margin-left: 0.4rem;
				width: 0.14rem;
				height: 0.24rem;
			}
			.word7 {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-weight: bold;
				font-size: 0.32rem;
			}
		}
	}
}

.seation1 {
	margin: 0 0.2rem;
	margin-top: 0.2rem;
	border-top-left-radius: 0.16rem;
	border-top-right-radius: 0.16rem;
	height: 1rem;
	line-height: 1rem;
	background-color: #FFFFFF;
	.qb {
		margin: 0 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.qb_left {
			display: flex;
			align-items: center;
			img {
				width: 0.6rem;
				height: 0.6rem;
			}
			.word7 {
				font-family: PingFangSC-Regular;
				margin-left: 0.12rem;
				color: #6F7989;
				font-size: 0.28rem;
			}
		}
		.qb_right {
			display: flex;
			align-items: center;
			img {
				margin-left: 0.4rem;
				width: 0.14rem;
				height: 0.24rem;
			}
			.word7 {
				color: #333333;
				font-weight: 500;
				font-size: 0.32rem;
			}
		}
	}
}
.seation2 {
	margin: 0 0.2rem;
	height: 1rem;
	line-height: 1rem;
	background-color: #FFFFFF;
	.qb {
		margin: 0 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.qb_left {
			display: flex;
			align-items: center;
			img {
				width: 0.6rem;
				height: 0.6rem;
			}
			.word7 {
				font-family: PingFangSC-Regular;
				margin-left: 0.12rem;
				color: #6F7989;
				font-size: 0.28rem;
			}
		}
		.qb_right {
			display: flex;
			align-items: center;
			img {
				margin-left: 0.4rem;
				width: 0.14rem;
				height: 0.24rem;
			}
			.word7 {
				color: #333333;
				font-weight: 500;
				font-size: 0.32rem;
			}
		}
	}
}
.seation3 {
	margin: 0 0.2rem;
	border-bottom-left-radius: 0.16rem;
	border-bottom-right-radius: 0.16rem;
	height: 1rem;
	line-height: 1rem;
	background-color: #FFFFFF;
	.qb {
		margin: 0 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.qb_left {
			display: flex;
			align-items: center;
			img {
				width: 0.6rem;
				height: 0.6rem;
			}
			.word7 {
				margin-left: 0.12rem;
				color: #6F7989;
				font-size: 0.28rem;
			}
		}
		.qb_right {
			display: flex;
			align-items: center;
			img {
				margin-left: 0.4rem;
				width: 0.14rem;
				height: 0.24rem;
			}
			.word7 {
				color: #333333;
				font-weight: 500;
				font-size: 0.32rem;
			}
		}
	}
}
.seation4 {
	margin: 0 0.2rem;
	margin-top: 0.2rem;
	border-top-left-radius: 0.16rem;
	border-top-right-radius: 0.16rem;
	height: 1rem;
	line-height: 1rem;
	background-color: #FFFFFF;
	.qb {
		margin: 0 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.qb_left {
			display: flex;
			align-items: center;
			img {
				width: 0.6rem;
				height: 0.6rem;
			}
			.word7 {
				font-family: PingFangSC-Regular;
				margin-left: 0.12rem;
				color: #6F7989;
				font-size: 0.28rem;
			}
		}
		.qb_right {
			display: flex;
			align-items: center;
			img {
				margin-left: 0.4rem;
				width: 0.14rem;
				height: 0.24rem;
			}
			.word7 {
				color: #333333;
				font-weight: 500;
				font-size: 0.32rem;
			}
		}
	}
}
.seation5 {
	margin: 0 0.2rem;
	height: 1rem;
	line-height: 1rem;
	background-color: #FFFFFF;
	.qb {
		margin: 0 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.qb_left {
			display: flex;
			align-items: center;
			img {
				width: 0.6rem;
				height: 0.6rem;
			}
			.word7 {
				font-family: PingFangSC-Regular;
				margin-left: 0.12rem;
				color: #6F7989;
				font-size: 0.28rem;
			}
		}
		.qb_right {
			display: flex;
			align-items: center;
			img {
				margin-left: 0.4rem;
				width: 0.14rem;
				height: 0.24rem;
			}
			.word7 {
				color: #333333;
				font-weight: 500;
				font-size: 0.32rem;
			}
		}
	}
}

.seation6 {
	margin: 0 0.2rem;
	height: 1rem;
	line-height: 1rem;
	background-color: #FFFFFF;
	.qb {
		margin: 0 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.qb_left {
			display: flex;
			align-items: center;
			img {
				width: 0.6rem;
				height: 0.6rem;
			}
			.word7 {
				font-family: PingFangSC-Regular;
				margin-left: 0.12rem;
				color: #6F7989;
				font-size: 0.28rem;
			}
		}
		.qb_right {
			display: flex;
			align-items: center;
			img {
				margin-left: 0.4rem;
				width: 0.14rem;
				height: 0.24rem;
			}
			.word7 {
				color: #333333;
				font-weight: 500;
				font-size: 0.32rem;
			}
		}
	}
}
.seation7 {
	margin: 0 0.2rem;
	height: 1rem;
	line-height: 1rem;
	background-color: #FFFFFF;
	border-bottom-left-radius: 0.16rem;
	border-bottom-right-radius: 0.16rem;
	.qb {
		margin: 0 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.qb_left {
			display: flex;
			align-items: center;
			img {
				width: 0.6rem;
				height: 0.6rem;
			}
			.word7 {
				font-family: PingFangSC-Regular;
				margin-left: 0.12rem;
				color: #6F7989;
				font-size: 0.28rem;
			}
		}
		.qb_right {
			display: flex;
			align-items: center;
			img {
				margin-left: 0.4rem;
				width: 0.14rem;
				height: 0.24rem;
			}
			.word7 {
				color: #333333;
				font-weight: 500;
				font-size: 0.32rem;
			}
		}
	}
}
.login_out {
	margin: 0 0.2rem;
	margin-top: 0.2rem;
	border-radius: 0.16rem;
	height: 1rem;
	line-height: 1rem;
	text-align: center;
	color: #2E62FF;
	background-color: #FFFFFF;
	font-weight: bold;
	font-size: 0.32rem;
}
.kong {
	width: 100%;
	height: 2.6rem;
}

.tab_bottom {
	display: flex;
	justify-content: space-between;
	position: fixed;
	left: 0;
	bottom: 0;
	height: 1.14rem;
	width: 100%;
	background-color: #ffffff;
	border-top: 0.005rem solid #dedede;
	.tab_item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0.14rem 0.8rem;
		.logo {
			width: 0.4rem;
			height: 0.4rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.logo1 {
			width: 0.32rem;
			height: 0.4rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.txt {
			color: #acb5bc;
			margin-top: 0.04rem;
			font-size: 0.24rem;
			transform: scale(0.916);
		}
		.active {
			color: #efba0d;
			font-family: PingFangSC-Medium;
		}
	}
}
.van-nav-bar__content {
	height: 1.6rem !important;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;